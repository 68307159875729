<template>
  <div>
    <ul>
      <li><router-link :to="{ name: 'StaffDashboard' }" @click="hideMenu">ダッシュボード</router-link></li>
      <li><router-link :to="{ name: 'EvalLog' }" @click="hideMenu">定期評価履歴</router-link></li>
      <li><router-link :to="{ name: 'BonusLog' }" @click="hideMenu">賞与履歴</router-link></li>
      <li><router-link :to="{ name: 'SelfEvalLog' }" @click="hideMenu">自己評価履歴</router-link></li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      domain: localStorage.getItem('domain')
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters['Auth/domain'],
      (newValue) => {
        this.domain = newValue
      }
    )
  },
  methods: {
    hideMenu () {
      this.$store.commit('showMenu', false)
    }
  },
  watch: {
  }
}
</script>
