import axios from 'axios'
// import router from '@/router'

const state = {
  evalList: {},
  evalLabels: {},
  evalData: {},
  wholeData: {},
  evalListPending: {},
  id: 0,
  mode: '',
  status: '',
  title: '',
  cat: '',
  point: '',
  grade: '',
  comment: {},
  notices: '',
  review: '',
  advice: '',
  evalRequest: false,
  evalRange: []
}

const getters = {
  evalList: (state) => state.evalList,
  evalLabels: (state) => state.evalLabels,
  evalListPending: (state) => state.evalListPending,
  id: (state) => state.id,
  wholeData: (state) => state.wholeData,
  evalData: (state) => state.evalData,
  mode: (state) => state.mode,
  status: (state) => state.status,
  title: (state) => state.title,
  cat: (state) => state.cat,
  point: (state) => state.point,
  grade: (state) => state.grade,
  comment: (state) => state.comment,
  notices: (state) => state.notices,
  review: (state) => state.review,
  advice: (state) => state.advice,
  evalRequest: (state) => state.evalRequest,
  evalRange: (state) => state.evalRange
}

const mutations = {
  evalList (state, evalList) {
    state.evalList = evalList
  },
  evalLabels (state, evalLabels) {
    state.evalLabels = evalLabels
  },
  evalListPending (state, evalListPending) {
    state.evalListPending = evalListPending
  },
  id (state, id) {
    state.id = id
  },
  wholeData (state, wholeData) {
    state.wholeData = wholeData
  },
  evalData (state, evalData) {
    state.evalData = evalData
  },
  mode (state, mode) {
    state.mode = mode
  },
  status (state, status) {
    state.status = status
  },
  title (state, title) {
    state.title = title
  },
  cat (state, cat) {
    state.cat = cat
  },
  point (state, point) {
    state.point = point
  },
  grade (state, grade) {
    state.grade = grade
  },
  comment (state, comment) {
    state.comment = comment
  },
  notices (state, notices) {
    state.notices = notices
  },
  review (state, review) {
    state.review = review
  },
  advice (state, advice) {
    state.advice = advice
  },
  evalRequest (state, evalRequest) {
    state.evalRequest = evalRequest
  },
  evalRange (state, evalRange) {
    state.evalRange = evalRange
  }
}

const actions = {
  //
  // 評価期間取得
  //
  async getEvalRange ({ commit }) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/eval/range')
        .then((response) => {
          // console.log(response.data)
          commit('evalRange', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 評価リクエスト有効化
  async evalActivate ({ rootGetters, commit, getters, dispatch }) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .post('/wp-json/jjs/v2/eval/activate/', getters.evalRange)
        .then((response) => {
          // console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
          dispatch('Client/loadClientData', null, { root: true })
          dispatch('getEvalRange')
          if (rootGetters.currentTemplateName === 'StaffList') {
            dispatch('Staff/loadStaffList', null, { root: true })
          }
        })
    })
  },
  //
  // New! 評価リクエスト有効化
  async evalRequest ({ rootGetters, commit, getters, dispatch }, cat) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .post('/wp-json/jjs/v2/eval/request', {
          cat: cat,
          range: getters.evalRange
        })
        .then((response) => {
          // console.log(response.data)
          commit('updated', true, { root: true })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
          dispatch('Client/loadClientData', null, { root: true })
          dispatch('getEvalRange')
          if (rootGetters.currentTemplateName === 'StaffList') {
            dispatch('Staff/loadStaffList', null, { root: true })
          }
        })
    })
  },
  //
  // New!! 評価リクエスト有効化
  async requestEval ({ commit, rootGetters, getters, dispatch }) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .post('/wp-json/jjs/v2/eval/request', {
          cat: rootGetters['Modal/mode'],
          range: getters.evalRange
        })
        .then((response) => {
          // console.log(response.data)
          commit('updated', true, { root: true })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
          dispatch('Client/loadClientData', null, { root: true })
          dispatch('getEvalRange')
          if (rootGetters.currentTemplateName === 'StaffList') {
            dispatch('Staff/loadStaffList', null, { root: true })
          }
        })
    })
  },
  //
  // 評価リクエスト無効化
  async evalInactivate ({ rootGetters, commit, dispatch }) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/eval/inactivate')
        .then((response) => {
          // console.log(response.data)
          commit('updated', true, { root: true })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
          dispatch('Client/loadClientData', null, { root: true })
          dispatch('getEvalRange')
          if (rootGetters.currentTemplateName === 'StaffList') {
            dispatch('Staff/loadStaffList', null, { root: true })
          }
        })
    })
  },
  // New! 評価リクエスト無効化
  async evalDeactivate ({ rootGetters, commit, dispatch }, cat) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/eval/deactivate/' + cat)
        .then((response) => {
          // console.log(response.data)
          commit('updated', true, { root: true })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
          dispatch('Client/loadClientData', null, { root: true })
          dispatch('Eval/getEvalRange')
          // dispatch('Staff/loadStaffList', null, { root: true })
        })
    })
  },
  //
  // 評価リクエスト状況取得
  async chkAskevalStatus ({ commit }) {
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/personal-askeval-status')
        .then((response) => {
          commit('askeval', response.data, { root: true })
        })
        .catch((error) => {
          console.log(error)
        })
    })
  },
  //
  // 【PATCH】社員昇格
  //
  async promoteStaff ({ getters, rootGetters, commit, dispatch }) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .patch('/wp-json/jjs/v2/eval/promote', rootGetters['Staff/staffData'].id)
        .then((response) => {
          console.log(response.data)
          dispatch('Staff/loadDetail', rootGetters['Staff/staffData'].id, { root: true })
          // const staffData = rootGetters['Staff/staffData']
          // const cls = rootGetters['Defs/classDefs']
          // if (parseInt(staffData.class) < parseInt(cls.no)) {
          //   staffData.class = parseInt(staffData.class) + 1
          //   staffData.lv = response.data
          //   staffData.point = 0
          //   commit('Staff/staffData', staffData, { root: true })
          // }
          commit('updated', true, { root: true })
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            dispatch('ShowError', '評価が許可されたユーザーではありません', { root: true })
          }
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 【add】一時保存
  async saveTemporary ({ rootGetters, getters, commit, dispatch }, staffId) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .post('/wp-json/jjs/v2/eval/save-temporary', {
          staffId: staffId,
          evalId: getters.id,
          cat: getters.cat, // 人事評価or賞与評価
          data: getters.evalData,
          point: getters.point,
          grade: getters.grade,
          notices: getters.notices,
          review: getters.review,
          advice: getters.advice
        })
        .then((response) => {
          console.log(response.data)
          commit('id', response.data.evalId)
          commit('status', response.data.status)
          // dispatch('loadEvalTeikiSheet', staffId) // 一時的にコメントアウト
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            dispatch('ShowError', '評価が許可されたユーザーではありません', { root: true })
          }
        })
        .finally(() => {
          commit('processing', false, { root: true })
          // if (rootGetters['Auth/capability'] === 'manager') {
          //   router.push({ name: 'ManagerDashboard' })
          // } else {
          //   router.push({ name: 'StaffDetail', params: { id: staffId, dom: rootGetters['Auth/domain'] } })
          // }
        })
    })
  },
  //
  // 【add】保存
  async saveEval ({ getters, rootGetters, commit, dispatch }, staffId) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .post('/wp-json/jjs/v2/eval/save/', {
          staffId: staffId,
          evalId: getters.id,
          cat: getters.cat, // 人事評価or賞与評価
          data: getters.evalData,
          point: getters.point,
          grade: getters.grade,
          review: getters.review,
          notices: getters.notices,
          advice: getters.advice
        })
        .then((response) => {
          console.log(response.data)
          commit('id', response.data.evalId)
          commit('status', response.data.status)
          // dispatch('Staff/loadDetail', staffId, { root: true })
          // dispatch('loadEvalTeikiSheet', staffId) // 一時的にコメントアウト
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            dispatch('ShowError', '評価が許可されたユーザーではありません', { root: true })
          }
        })
        .finally(() => {
          commit('processing', false, { root: true })
          dispatch('Manager/loadNeedEvalList', null, { root: true })
          // if (rootGetters['Auth/capability'] === 'manager') {
          //   router.push({ name: 'ManagerDashboard' })
          // } else {
          //   router.push({ name: 'StaffDetail', params: { id: staffId, dom: rootGetters['Auth/domain'] } })
          // }
        })
    })
  },
  //
  // 【get】当該スタッフ評価リスト取得
  //
  async getList ({ commit }, staffId) {
    // console.log('getList')
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/eval/list/' + staffId)
        .then((response) => {
          // console.log(response.data)
          commit('evalList', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 【get】当該スタッフ定期評価リスト取得
  async getPeriodicalList ({ commit }, staffId) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/eval/periodical/list/' + staffId)
        .then((response) => {
          commit('evalList', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 【get】当該スタッフ賞与評価リスト取得
  async getBonusList ({ commit }, staffId) {
    // console.log('getBonusList')
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/eval/bonus/list/' + staffId)
        .then((response) => {
          // console.log(response.data)
          commit('evalList', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 【get】評価記録詳細取得
  async getDetail ({ getters, commit, dispatch }, id) {
    // console.log('getDetail')
    commit('processing', true, { root: true })
    dispatch('resetEvalData')
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/eval/' + id)
        .then((response) => {
          console.log(response.data)
          commit('wholeData', response.data)
          commit('evalData', response.data.data)
          commit('id', response.data.ID)
          commit('status', response.data.status)
          commit('title', response.data.title)
          commit('point', response.data.point)
          commit('grade', response.data.grade)
          commit('review', response.data.review)
          commit('comment', response.data.comment)
          commit('advice', response.data.advice)
          dispatch('Staff/loadDetail', response.data.staffId, { root: true })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 【update】評価記録詳細更新
  async updateEval ({ getters, commit, dispatch }, staffId) {
    return new Promise((resolve) => {
      axios
        .patch('/wp-json/jjs/v2/eval/update/', {
          staffId: staffId,
          evalId: getters.evalId,
          status: getters.status,
          data: getters.evalData,
          point: getters.point,
          grade: getters.grade,
          comment: getters.comment,
          review: getters.review,
          advice: getters.advice
        })
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            dispatch('ShowError', '評価が許可されたユーザーではありません', { root: true })
          }
        })
    })
  },
  //
  // 【get】一時保存中／2次評価待ち評価記録リスト
  async getEvalPendingList ({ rootGetters, commit }) {
    const capability = rootGetters['Auth/capability']
    if (capability === 'manager') {
      commit('processing', true, { root: true })
      return new Promise((resolve) => {
        axios
          .get('/wp-json/jjs/v2/eval/list/pending')
          .then((response) => {
            console.log(response.data)
            commit('evalListPending', response.data)
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            commit('processing', false, { root: true })
          })
      })
    }
  },
  //
  // 当該スタッフに紐づいた人事評価を取得
  async loadExistingEval ({ commit, dispatch }, staffId) {
    // console.log('loadExistingEval')
    commit('processing', true, { root: true })
    dispatch('resetEvalData')
    console.log('checking existing eval...')
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/eval/staffid/' + staffId)
        .then((response) => {
          // console.log(response.data)
          commit('evalData', response.data.data)
          commit('id', response.data.ID)
          commit('status', response.data.status)
          commit('title', response.data.title)
          commit('point', response.data.point)
          commit('grade', response.data.grade)
          commit('review', response.data.review)
          commit('comment', response.data.comment)
          commit('advice', response.data.advice)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 当該スタッフに紐づいた賞与評価を取得
  async loadExistingEvalBonus ({ commit, dispatch }, staffId) {
    // console.log('loadExistingEvalBonus')
    commit('processing', true, { root: true })
    dispatch('resetEvalData')
    console.log('checking existing eval bonus...')
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/evalbonus/staffid/' + staffId)
        .then((response) => {
          console.log(response.data)
          commit('evalData', response.data.data)
          commit('id', response.data.ID)
          commit('status', response.data.status)
          commit('title', response.data.title)
          commit('point', response.data.point)
          commit('grade', response.data.grade)
          commit('notices', response.data.notices)
          commit('review', response.data.review)
          commit('advice', response.data.advice)
          commit('comment', response.data.comment)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 定期評価シートデータ取得
  async loadEvalTeikiSheet ({ commit }, staffId) {
    commit('processing', true, { root: true })
    console.log('load eval teki sheet...')
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/eval/sheet/teiki/' + staffId)
        .then((response) => {
          console.log(response.data)
          commit('Staff/staffData', response.data.staffData, { root: true })
          commit('evalData', response.data.evalData)
          commit('id', response.data.evalId)
          commit('evalLabels', response.data.labels)
          commit('review', response.data.review)
          commit('status', response.data.status)
          commit('grade', response.data.grade)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 賞与評価シートデータ取得
  async loadEvalBonusSheet ({ commit, dispatch }, staffId) {
    dispatch('resetEvalData')
    commit('processing', true, { root: true })
    console.log('load eval bonus sheet...')
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/eval/sheet/bonus/' + staffId)
        .then((response) => {
          console.log(response.data)
          commit('Staff/staffData', response.data.staffData, { root: true })
          commit('evalData', response.data.evalData)
          commit('id', response.data.evalId)
          commit('evalLabels', response.data.labels)
          commit('notices', response.data.notices)
          commit('advice', response.data.advice)
          commit('review', response.data.review)
          commit('status', response.data.status)
          commit('grade', response.data.grade)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 【reset】リセット
  //
  resetEvalData ({ commit }) {
    commit('wholeData', {})
    commit('evalData', {})
    commit('id', '')
    commit('status', '')
    commit('title', '')
    commit('cat', '')
    commit('point', '')
    commit('grade', '')
    commit('comment', {})
    commit('review', '')
    commit('notices', '')
    commit('advice', '')
  }
}

const Eval = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default Eval
