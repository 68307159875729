import axios from 'axios'
import router from '@/router'

const state = {
  clientData: JSON.parse(localStorage.getItem('clientData'))
}

const getters = {
  clientData: (state) => state.clientData
}

const mutations = {
  clientData (state, clientData) {
    state.clientData = clientData
    localStorage.setItem('clientData', JSON.stringify(clientData))
    // console.log('clientdata updated')
  }
}

const actions = {
  //
  // クライアント情報取得
  //
  async loadClientData ({ rootGetters, commit }) {
    // console.log('load client whole data')
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios.defaults.headers.common.Authorization = `Bearer ${rootGetters['Auth/token']}`
      axios
        .get('/wp-json/jjs/v2/client/wholedata')
        .then((response) => {
          console.log(response.data)
          commit('clientData', response.data)
          commit('company', response.data.title, { root: true })
          commit('Auth/domain', response.data.slug, { root: true })
          commit('Auth/currentCompany', response.data.title, { root: true })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // クライアント情報更新
  //
  updateClientOptions ({ rootGetters, getters }) {
    axios.defaults.headers.common.Authorization = `Bearer ${rootGetters['Auth/token']}`
    axios
      .patch('/wp-json/jjs/v2/client/update/options', getters.clientData)
      .then((response) => {
        // console.log(response.data)
        alert('企業情報を更新しました')
      })
      .catch((error) => {
        console.log(error)
      })
  },
  //
  // クライアント追加
  //
  async addClient ({ getters, commit }) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .post('/wp-json/jjs/v2/client/add', getters.clientData)
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
          router.push({ name: 'AdminDashboard' })
        })
    })
  },
  //
  // クライアント削除
  async deleteClient ({ commit, dispatch }, id) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .delete('/wp-json/jjs/v2/client/delete/' + id)
        .then((response) => {
          console.log(response.data)
          dispatch('Admin/loadClientList', null, { root: true })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // クライアント復元
  async restoreClient ({ commit, dispatch }, id) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/client/restore/' + id)
        .then((response) => {
          console.log(response.data)
          dispatch('Admin/loadClientList', null, { root: true })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 職域設定同期
  //
  syncDomain ({ getters, commit }, data) {
    const clientData = getters.clientData
    clientData.settings.domains = data
  },
  //
  // 設定更新
  //
  updateSetting ({ getters, commit }, key) {
    if (key in getters.clientData.settings) {
      axios
        .patch('/wp-json/jjs/v2/client/settings/update', {
          key: key,
          container: getters.clientData.settings[key]
        })
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}

const Client = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default Client
