<template>
  <div>
    <div class="gradeindicator">
      <label>現在のポイント</label>
      <div>
        <span class="point" :class="{ enough: enough }">
          <em>{{ point }}</em><i>/{{ targetPoint }}</i>
        </span>
      </div>
    </div>
    <div class="actions">
      <router-link :to="{ name: 'StaffDetail' }">
        <div>
          <i class="fa-solid fa-address-card"></i>プロフィール
        </div>
      </router-link>
      <router-link :to="{ name: 'StaffHistory' }">
        <div>
          <i class="fa-solid fa-chart-line"></i>個人履歴
        </div>
      </router-link>
      <router-link :to="{ name: 'StaffHistoryEval' }">
        <div>
          <i class="fa-solid fa-award"></i>評価履歴
        </div>
      </router-link>
      <router-link :to="{ name: 'StaffHistoryBonus' }">
        <div>
          <i class="fa-solid fa-award"></i>賞与履歴
        </div>
      </router-link>
      <router-link :to="{ name: 'ManagerOneOnOne' }">
        <div>
          <i class="oneonone"></i>
          </div>
      </router-link>
    </div>
    <div class="qrcode">
      <h5>スマートフォンアクセス用QRコード</h5>
      <vue-qr
        v-if="qrUrl"
        :text="qrUrl"
      ></vue-qr>
    </div>
  </div>
</template>

<script>
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
export default {
  components: {
    vueQr
  },
  data () {
    return {
      cap: localStorage.getItem('capability'),
      staffname: this.$store.getters['Staff/staffData'].nickname,
      qrUrl: this.$store.getters['Staff/staffData'].url
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters['Auth/capability'],
      (newValue) => {
        this.cap = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters['Staff/staffData'],
      (newValue) => {
        this.staffname = newValue.nickname
        this.qrUrl = newValue.url
      }
    )
  },
  methods: {
  },
  computed: {
    point () {
      let point = this.$store.getters['Staff/staffData'].point
      if (!point || point === undefined) {
        point = 0
      }
      return point
    },
    targetPoint () {
      const arr = this.$store.getters['Defs/classDefs'].point
      const cls = this.$store.getters['Staff/staffData'].class
      return arr && cls ? arr[parseInt(cls) - 1] : 0
    },
    enough () {
      return parseInt(this.point) !== 0 && parseInt(this.point) >= parseInt(this.targetPoint)
    }
  }
}
</script>

<style scoped>
@media (min-width: 1700px) {
  .actions > div,
  .actions > a {
    padding: 15px;
    font-size: 0.9rem;
  }
}
@media (max-width: 1699px) {
  .actions > div,
  .actions > a {
    padding: 5px;
    font-size: 14px;
  }
}
.grades {
  margin-bottom: 25px;
}
.grades > div {
  border: 2px solid var(--border-color);
  background-color: #fff;
  padding: 15px;
  margin-bottom: 5px;
  position: relative;
  min-height: 80px;
}
.grades label {
  position: absolute;
  top: 0;
  left: 3px;
  font-size: 12px;
  font-weight: bold;
}
.actions {
  margin-bottom: 30px;
}
.actions > div,
.actions > a {
  display: block;
  margin-bottom: 5px;
  border: 3px solid var(--theme-color);
  color: var(--theme-color);
  background-color: #fff;
  display: grid;
  align-items: center;
  justify-items: center;
  font-weight: bold;
  text-align: center;
  user-select: none;
}
.actions > div:hover {
  cursor: pointer;
}
.actions > div.current {
  background-color: var(--theme-color);
  color: #fff;
}
.actions i {
  display: block;
  text-align: center;
  font-size: 30px;
}
</style>
