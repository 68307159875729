import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import Dashboard from '../views/DashboardView.vue'

const routes = [
  {
    // Dashboard
    path: '/',
    name: 'dashboard',
    meta: { title: 'ダッシュボード' },
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if (store.getters['Auth/userid'] === null) {
        next({ name: 'login' })
      }
      switch (store.getters['Auth/capability']) {
        case 'superadmin':
          next({ name: 'AdminDashboard' })
          break
        case 'owner':
          next({ name: 'OwnerLobby' })
          // next({ name: 'Client', params: { dom: store.getters['Auth/domain'] } })
          break
        default:
          next({ name: 'StaffDashboard' })
      }
    }
  },
  {
    // Login
    path: '/login',
    name: 'login',
    meta: { title: 'ログイン' },
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/:dom/login',
    children: [
      {
        // Login（クライアント）
        path: '',
        name: 'ClientLogin',
        meta: { title: 'クライアントログイン' },
        component: () => import(/* webpackChunkName: "ClientLogin" */ '../views/ClientLogin.vue')
      },
      {
        // Login（スタッフ）
        path: ':id/:param',
        name: 'StaffLogin',
        meta: { title: 'スタッフログイン' },
        component: () => import(/* webpackChunkName: "StaffLogin" */ '../views/StaffLogin.vue')
      }
    ]
  },
  {
    // 【社労士】ダッシュボード
    path: '/admin/dashboard',
    name: 'AdminDashboard',
    meta: { title: '社労士ダッシュボード' },
    component: () => import(/* webpackChunkName: "AdminDashboard" */ '../views/Admin/AdminDashboard.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('Auth/restoreMasterLogin')
      store.dispatch('Admin/loadClientList')
      next()
    }
  },
  {
    // 【社労士】クライアント追加
    path: '/admin/add-client',
    name: 'AddClient',
    meta: { title: 'クライアント新規追加' },
    component: () => import(/* webpackChunkName: "AddClient" */ '../views/Admin/AddClient.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('Auth/restoreMasterLogin')
      store.dispatch('Admin/loadClientList')
      next()
    }
  },
  {
    // 【Owner】Owner Lobby
    path: '/ownerlobby',
    name: 'OwnerLobby',
    meta: { title: '企業リスト' },
    component: () => import(/* webpackChunkName: "OwnerLobby" */ '../views/Owner/OwnerLobby.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('Auth/restoreMasterLogin')
      store.dispatch('Owner/loadCompanyList')
      next()
    }
  },
  {
    // 【Owner】
    path: '/:dom',
    beforeEnter: (to, from, next) => {
      // store.dispatch('Auth/branchLogin', to.params.dom)
      store.dispatch('Auth/clientLogin', to.params.dom)
      next()
    },
    children: [
      {
        // 【Owner】ダッシュボード
        path: '',
        name: 'Client',
        meta: { title: 'ダッシュボード' },
        component: () => import(/* webpackChunkName: "Client" */ '../views/ClientDashboard.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Auth/clientLogin', to.params.dom)
          next()
        }
      },
      {
        // 【Def】企業情報サマリー
        path: 'companyinfo',
        name: 'ClientSummary',
        meta: { title: '企業情報' },
        component: () => import(/* webpackChunkName: "ClientSummary" */ '../views/ClientSummary.vue')
      },
      {
        // 【Def】部門定義
        path: 'domain',
        name: 'DomainDef',
        meta: { title: '部門定義' },
        component: () => import(/* webpackChunkName: "DomainDef" */ '../views/Defs/Domain.vue')
      },
      {
        // 【Def】役割定義
        path: 'role',
        name: 'RoleDef',
        meta: { title: '役割定義' },
        component: () => import(/* webpackChunkName: "RoleDef" */ '../views/Defs/Role.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Defs/loadClassDefs')
          next()
        }
      },
      {
        // 【Def】昇格・昇給
        path: 'grade',
        name: 'GradeDef',
        meta: { title: '評価・昇給' },
        component: () => import(/* webpackChunkName: "GradeDef" */ '../views/Defs/Grade.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Defs/loadPromoteDefs')
          // store.dispatch('Defs/loadRankDefs')
          store.dispatch('Defs/loadClassDefs')
          store.dispatch('Defs/loadPromoteConds')
          store.dispatch('Defs/loadPromoteAproxyears')
          next()
        }
      },
      {
        // 【Def】初任給
        path: 'initial',
        name: 'InitialDef',
        meta: { title: '初任給' },
        component: () => import(/* webpackChunkName: "InitialDef" */ '../views/Defs/Initial.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Client/loadClientData')
          next()
        }
      },
      {
        // 【Def】給与テーブル
        path: 'salary',
        name: 'SalaryDef',
        meta: { title: '基本給・号数' },
        component: () => import(/* webpackChunkName: "SalaryDef" */ '../views/Defs/Salary.vue'),
        beforeEnter: (to, from, next) => {
          // store.dispatch('Defs/loadBonusDef')
          store.dispatch('Defs/loadClassDefs')
          // store.dispatch('Defs/loadRankDefs')
          store.dispatch('Defs/loadPromoteDefs')
          next()
        }
      },
      {
        // 【Def】キャリア採用
        path: 'career',
        name: 'CareerDef',
        meta: { title: 'キャリア採用' },
        component: () => import(/* webpackChunkName: "CareerDef" */ '../views/Defs/Career.vue'),
        beforeEnter: (to, from, next) => {
          // store.dispatch('Defs/loadRankDefs')
          store.dispatch('Defs/loadClassDefs')
          store.dispatch('Defs/loadInitialDefs')
          store.dispatch('Defs/loadSalaryDefs')
          store.dispatch('Defs/loadPromoteDefs')
          store.dispatch('Defs/loadRecruitDef')
          next()
        }
      },
      {
        // 【Def】手当
        path: 'allowance',
        name: 'AllowanceDef',
        meta: { title: '手当' },
        component: () => import(/* webpackChunkName: "AllowanceDef" */ '../views/Defs/Allowance.vue')
      },
      {
        // 【Def】賞与
        path: 'bonus',
        name: 'BonusDef',
        meta: { title: '賞与' },
        component: () => import(/* webpackChunkName: "BonusDef" */ '../views/Defs/Bonus.vue'),
        beforeEnter: (to, from, next) => {
          store.commit('Staff/staffList', {})
          store.dispatch('Defs/loadBonusDef')
          store.dispatch('Staff/loadStaffList')
          next()
        }
      },
      {
        // 【Def】評価項目（定期評価）
        path: 'eval',
        name: 'EvalDef',
        meta: { title: '評価項目（定期評価）' },
        component: () => import(/* webpackChunkName: "EvalDef" */ '../views/Defs/Eval.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Defs/loadClassDefs')
          store.dispatch('Defs/loadEvalDefs')
          next()
        }
      },
      {
        // 【Def】評価項目（賞与評価）
        path: 'eval-bonus',
        name: 'EvalBonusDef',
        meta: { title: '評価項目（賞与評価）' },
        component: () => import(/* webpackChunkName: "EvalBonusDef" */ '../views/Defs/EvalBonus.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Defs/loadClassDefs')
          store.dispatch('Defs/loadEvalDefs')
          store.dispatch('Defs/loadEvalDefsBonus')
          next()
        }
      },
      {
        // 【Def】評価項目（賞与評価）* backup *
        path: 'eval-bonus2',
        name: 'EvalBonusDef2',
        meta: { title: '評価項目（賞与評価）' },
        component: () => import(/* webpackChunkName: "EvalBonusDef2" */ '../views/Defs/EvalBonus_backup.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Defs/loadClassDefs')
          store.dispatch('Defs/loadEvalDefsBonus')
          next()
        }
      },
      {
        // 【管理職】ダッシュボード
        path: 'ManagerDashboard',
        name: 'ManagerDashboard',
        meta: { title: '管理職ダッシュボード' },
        component: () => import(/* webpackChunkName: "ManagerDashboard" */ '../views/Manager/DashboardView.vue'),
        beforeEnter: (to, from, next) => {
          if (store.getters['Auth/token']) {
            store.commit('Manager/staffList', {})
            store.dispatch('Eval/getEvalPendingList')
          }
          next()
        }
      }
    ]
  },
  {
    // 【社員】
    path: '/:dom/staff',
    beforeEnter: (to, from, next) => {
      store.dispatch('Auth/branchLogin', to.params.dom)
      next()
    },
    children: [
      {
        // 【スタッフ】一覧
        path: '',
        name: 'StaffList',
        meta: { title: '社員一覧' },
        component: () => import(/* webpackChunkName: "StaffList" */ '../views/Owner/StaffList.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Staff/loadStaffList')
          next()
        }
      },
      {
        // 【スタッフ】詳細
        path: ':id',
        children: [
          {
            // 【スタッフ】詳細
            path: '',
            name: 'StaffDetail',
            component: () => import(/* webpackChunkName: "StaffDetail" */ '../views/StaffDetail.vue'),
            meta: { title: '社員詳細データ' },
            beforeEnter: (to, from, next) => {
              store.commit('Staff/staffData', {})
              store.dispatch('Staff/loadDetail', to.params.id)
              store.dispatch('Defs/loadClassDefs')
              store.commit('mode', 'profile')
              store.dispatch('SelfExam/loadListById', to.params.id)
              next()
            }
          },
          {
            // 【スタッフ】個人履歴
            path: 'history',
            beforeEnter: (to, from, next) => {
              store.dispatch('Staff/loadDetail', to.params.id)
              store.dispatch('Staff/getHistory', to.params.id)
              next()
            },
            children: [
              {
                // 【スタッフ】個人履歴
                path: '',
                name: 'StaffHistory',
                component: () => import(/* webpackChunkName: "StaffHistory" */ '../views/StaffHistory.vue'),
                meta: { title: '個人履歴' },
                beforeEnter: (to, from, next) => {
                  store.dispatch('Staff/getInitialStatus', to.params.id)
                  next()
                }
              },
              {
                // 【スタッフ】評価履歴
                path: 'evaluation',
                name: 'StaffHistoryEval',
                component: () => import(/* webpackChunkName: "StaffHistoryEval" */ '../views/StaffHistoryEval.vue'),
                meta: { title: '評価履歴' },
                beforeEnter: (to, from, next) => {
                  store.dispatch('Eval/getPeriodicalList', to.params.id)
                  next()
                }
              },
              {
                // 【スタッフ】賞与履歴
                path: 'bonus',
                name: 'StaffHistoryBonus',
                component: () => import(/* webpackChunkName: "StaffHistoryBonus" */ '../views/StaffHistoryBonus.vue'),
                meta: { title: '評価履歴' },
                beforeEnter: (to, from, next) => {
                  store.dispatch('Eval/getBonusList', to.params.id)
                  next()
                }
              }
            ]
          },

          {
            // 【スタッフ】定期評価
            path: 'eval/teiki',
            name: 'EvalTeiki',
            meta: { title: '定期評価' },
            component: () => import(/* webpackChunkName: "EvalTeiki" */ '../views/Manager/EvalTeiki.vue'),
            beforeEnter: (to, from, next) => {
              // store.dispatch('Staff/loadDetail', to.params.id)
              // store.dispatch('Defs/loadEvalDefs')
              // store.dispatch('Eval/loadExistingEval', to.params.id)
              store.dispatch('Eval/loadEvalTeikiSheet', to.params.id)
              next()
            }
          },
          {
            // 【スタッフ】賞与評価
            path: 'eval/bonus',
            name: 'EvalBonus',
            meta: { title: '賞与評価' },
            component: () => import(/* webpackChunkName: "EvalBonus" */ '../views/Manager/EvalBonus.vue'),
            beforeEnter: (to, from, next) => {
              store.dispatch('Staff/loadDetail', to.params.id)
              // store.dispatch('Defs/loadEvalBonusDefs')
              // store.dispatch('Eval/loadExistingEvalBonus', to.params.id)
              store.dispatch('Eval/loadEvalBonusSheet', to.params.id)
              next()
            }
          },
          {
            // 【スタッフ】１on１
            path: '1on1',
            name: 'ManagerOneOnOne',
            meta: { title: '１on１' },
            component: () => import(/* webpackChunkName: "ManagerOneOnOne" */ '../views/Manager/OneOnOne.vue'),
            beforeEnter: (to, from, next) => {
              store.dispatch('Staff/loadDetail', to.params.id)
              store.dispatch('OneOnOne/loadlist', to.params.id)
              next()
            }
          }
        ]
      },
      {
        // 【Owner】賞与承認
        path: 'paybonus',
        name: 'PayBonus',
        meta: { title: '賞与承認' },
        component: () => import(/* webpackChunkName: "PayBonus" */ '../views/Owner/PayBonus.vue'),
        beforeEnter: (to, from, next) => {
          store.commit('Staff/staffList', [])
          store.dispatch('Staff/loadStaffListBonus')
          store.dispatch('Defs/loadBonusDef')
          store.dispatch('Eval/getEvalRange')
          next()
        }
      },
      {
        // 【Owner】昇格承認
        path: 'approve-promote',
        name: 'ApprovePromote',
        meta: { title: '昇格承認' },
        component: () => import(/* webpackChunkName: "ApprovePromote" */ '../views/Owner/ApprovePromote.vue'),
        beforeEnter: (to, from, next) => {
          store.commit('Staff/staffList', [])
          store.dispatch('Staff/loadStaffListRaise')
          store.dispatch('Owner/loadStaffListPromote')
          store.dispatch('Defs/loadSalaryDefs')
          store.dispatch('Defs/loadClassDefs')
          store.dispatch('Defs/loadRankDefs')
          store.dispatch('Eval/getEvalRange')
          // store.dispatch('Defs/loadBonusDef')
          next()
        }
      }
    ]
  },
  {
    // 【スタッフ】評価グループ
    path: '/:dom/group',
    name: 'EvalGroup',
    meta: { title: '評価グループ' },
    component: () => import(/* webpackChunkName: "EvalGroup" */ '../views/Owner/EvalGroup.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('Auth/branchLogin', to.params.dom)
      next()
    }
  },
  {
    // 【スタッフ】追加
    path: '/:dom/staff/add',
    name: 'StaffAdd',
    meta: { title: '社員追加' },
    component: () => import(/* webpackChunkName: "StaffAdd" */ '../views/Owner/AddStaff.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('Auth/branchLogin', to.params.dom)
      next()
    }
  },
  {
    // 【人事評価】詳細
    path: '/:dom/eval',
    beforeEnter: (to, from, next) => {
      store.dispatch('Auth/branchLogin', to.params.dom)
      // store.dispatch('Defs/loadEvalDefs')
      next()
    },
    children: [
      {
        // 【定期評価】詳細
        path: 'teiki/:id',
        name: 'EvalTeikiDetail',
        meta: { title: '評価詳細' },
        component: () => import(/* webpackChunkName: "EvalTeikiDetail" */ '../views/ShowOnly/SOEvalTeiki.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Eval/resetEvalData')
          store.dispatch('Eval/getDetail', to.params.id)
          next()
        }
      },
      {
        // 【賞与評価】詳細
        path: 'bonus/:id',
        name: 'EvalBonusDetail',
        meta: { title: '評価詳細' },
        component: () => import(/* webpackChunkName: "EvalBonusDetail" */ '../views/ShowOnly/SOEvalBonus.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Eval/resetEvalData')
          store.dispatch('Eval/getDetail', to.params.id)
          next()
        }
      }
    ]
  },
  // 【スタッフ】ダッシュボード
  {
    path: '/dashboard',
    name: 'StaffDashboard',
    meta: { title: 'スタッフダッシュボード' },
    component: () => import(/* webpackChunkName: "StaffDashboard" */ '../views/Staff/DashboardView.vue'),
    beforeEnter: (to, from, next) => {
      store.commit('Staff/staffData', {})
      store.dispatch('Auth/clientLogin', store.getters['Auth/domain'])
      store.dispatch('Staff/loadDetail', store.getters['Auth/userid'])
      store.dispatch('Eval/getPeriodicalList', store.getters['Auth/userid'])
      next()
    }
  },
  // 【スタッフ】自己評価
  {
    path: '/selfeval',
    beforeEnter: (to, from, next) => {
      store.dispatch('Auth/clientLogin', store.getters['Auth/domain'])
      store.dispatch('Staff/loadDetail', store.getters['Auth/userid'])
      next()
    },
    children: [
      {
        // 【スタッフ】自己評価記入（定期）
        path: '',
        name: 'SelfEvaluation',
        meta: { title: '自己評価記入(定期評価)' },
        component: () => import(/* webpackChunkName: "SelfEvaluation" */ '../views/Staff/SelfEvaluation.vue'),
        beforeEnter: (to, from, next) => {
          store.commit('SelfExam/cat', 'periodical')
          store.commit('SelfExam/status', 'addnew')
          store.dispatch('SelfExam/reset')
          store.dispatch('SelfExam/getEvalRange', 'periodical')
          store.dispatch('SelfExam/chkIfDone', store.getters['Auth/userid'])
          next()
        }
      },
      {
        // 【スタッフ】自己評価記入（賞与）
        path: 'bonus',
        name: 'SelfEvalBonus',
        meta: { title: '自己評価記入(賞与評価)' },
        component: () => import(/* webpackChunkName: "SelfEvalBonus" */ '../views/Staff/SelfEvalBonus.vue'),
        beforeEnter: (to, from, next) => {
          store.commit('SelfExam/cat', 'bonus')
          store.commit('SelfExam/status', 'addnew')
          store.dispatch('SelfExam/reset')
          store.dispatch('SelfExam/getEvalRange', 'bonus')
          store.dispatch('SelfExam/chkIfDone', store.getters['Auth/userid'])
          next()
        }
      },
      {
        // 【スタッフ】自己評価履歴
        path: 'log',
        name: 'SelfEvalLog',
        meta: { title: '自己評価' },
        component: () => import(/* webpackChunkName: "SelfEvalLog" */ '../views/Staff/SelfEvalLog.vue')
      },
      {
        // 【スタッフ】自己評価詳細(定期)
        path: ':id',
        name: 'SelfEvalDetail',
        meta: { title: '自己評価' },
        component: () => import(/* webpackChunkName: "SelfEvalDetail" */ '../views/Staff/SelfEvaluation.vue'),
        // component: () => import(/* webpackChunkName: "SelfEvalDetail" */ '../views/Staff/SelfEvalDetail.vue'),
        beforeEnter: (to, from, next) => {
          store.commit('SelfExam/cat', 'periodical')
          store.dispatch('SelfExam/loadDetail', to.params.id)
          next()
        }
      },
      {
        // 【スタッフ】自己評価詳細(賞与)
        path: 'bonus/:id',
        name: 'SelfEvalBonusDetail',
        meta: { title: '自己評価' },
        component: () => import(/* webpackChunkName: "SelfEvalBonusDetail" */ '../views/Staff/SelfEvalBonus.vue'),
        // component: () => import(/* webpackChunkName: "SelfEvalBonusDetail" */ '../views/Staff/SelfEvalBonusDetail.vue'),
        beforeEnter: (to, from, next) => {
          store.commit('SelfExam/cat', 'bonus')
          store.dispatch('SelfExam/loadDetail', to.params.id)
          next()
        }
      }
    ]
  },
  // 【スタッフ】フィードバック
  {
    path: '/feedback',
    beforeEnter: (to, from, next) => {
      store.dispatch('Auth/branchLogin', store.getters['Auth/domain'])
      next()
    },
    children: [
      {
        path: '',
        name: 'FeedbackView',
        meta: { title: 'フィードバック' },
        component: () => import(/* webpackChunkName: "FeedbackView" */ '../views/Staff/FeedbackView.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Eval/getPeriodicalList', store.getters['Auth/userid'])
          next()
        }
      },
      {
        path: ':id',
        name: 'FeedbackDetail',
        meta: { title: 'フィードバック' },
        component: () => import(/* webpackChunkName: "FeedbackDetail" */ '../views/Staff/FeedbackDetail.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Eval/getDetail', to.params.id)
          next()
        }
      }
    ]
  },
  // 【スタッフ】評価履歴
  {
    path: '/eval-log',
    name: 'EvalLog',
    meta: { title: '評価履歴' },
    component: () => import(/* webpackChunkName: "EvalLog" */ '../views/Staff/EvalLog.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('Auth/clientLogin', store.getters['Auth/domain'])
      store.dispatch('Eval/getPeriodicalList', store.getters['Auth/userid'])
      store.dispatch('Staff/loadDetail', store.getters['Auth/userid'])
      next()
    }
  },
  // 【スタッフ】賞与履歴
  {
    path: '/bonus-log',
    name: 'BonusLog',
    meta: { title: '賞与履歴' },
    component: () => import(/* webpackChunkName: "BonusLog" */ '../views/Staff/BonusLog.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('Auth/clientLogin', store.getters['Auth/domain'])
      store.dispatch('Eval/getBonusList', store.getters['Auth/userid'])
      store.dispatch('Staff/loadDetail', store.getters['Auth/userid'])
      next()
    }
  },
  // 【スタッフ】辞令
  {
    path: '/jirei/:id',
    name: 'Jirei',
    meta: { title: '賞与履歴' },
    component: () => import(/* webpackChunkName: "Jirei" */ '../views/Staff/JireiView.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('Auth/clientLogin', store.getters['Auth/domain'])
      store.dispatch('Jirei/getJirei', to.params.id)
      next()
    }
  },
  // 【スタッフ】１on１
  {
    path: '/1on1',
    name: 'OneOnOne',
    meta: { title: '１on１' },
    component: () => import(/* webpackChunkName: "OneOnOne" */ '../views/Staff/OneOnOne.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('Auth/clientLogin', store.getters['Auth/domain'])
      store.dispatch('Staff/loadDetail', store.getters['Auth/userid'])
      store.dispatch('OneOnOne/loadlist', store.getters['Auth/userid'])
      next()
    }
  },
  // マスターデータ管理
  {
    path: '/:dom/master',
    beforeEnter: (to, from, next) => {
      store.dispatch('Auth/branchLogin', store.getters['Auth/domain'])
      next()
    },
    children: [
      {
        path: '',
        name: 'EmployeeMaster',
        meta: { title: '社員マスター' },
        component: () => import(/* webpackChunkName: "EmployeeMaster" */ '../views/Owner/EmployeeMaster.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Staff/loadStaffMaster', '在籍')
          store.dispatch('Defs/loadClassDefs')
          next()
        }
      },
      {
        path: 'bulk-regist',
        name: 'BulkRegistration',
        meta: { title: '社員一括登録' },
        component: () => import(/* webpackChunkName: "BulkRegistration" */ '../views/Owner/BulkRegistration.vue'),
        beforeEnter: (to, from, next) => {
          next()
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('currentTemplateName', to.name)
  store.commit('showMenu', false)
  next()
})

export default router
