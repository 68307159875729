<template>
  <nav :class="{ active: menu }">
    <div id="titlebar">
      <h1><router-link to="/"><span>人事評価</span></router-link></h1>
      <div class="togglemenu" @click="toggleMenu"></div>
    </div>
    <div id="nav-itemis">
      <AdminMenu v-if="capability === 'superadmin'"></AdminMenu>
      <OwnerMenu v-else-if="capability === 'owner'"></OwnerMenu>
      <ManagerMenu v-else-if="capability === 'manager'"></ManagerMenu>
      <StaffMenu v-else></StaffMenu>
      <ul>
        <li
          v-if="restorable"
          class="restoreLogin"
          @click="restoreOriginalLogin"
        ><span><i class="fa-solid fa-arrow-up-from-bracket"></i>管理者に戻る</span></li>
        <li @click="logout"><span>ログアウト</span></li>
      </ul>
    </div>
  </nav>
  <main>
    <header>
      <PageTitle></PageTitle>
      <CurrentUser></CurrentUser>
    </header>
    <router-view/>
  </main>
  <aside>
    <Utility></Utility>
  </aside>
  <ModalWindow v-if="modal"></ModalWindow>
  <div
    id="errormsg"
    v-if="error"
    @click="resetError"
  >
    <div>{{ errorMsg }}</div>
  </div>
</template>

<script>
import AdminMenu from '@/components/LeftSideMenu/AdminMenu.vue'
import OwnerMenu from '@/components/LeftSideMenu/OwnerMenu.vue'
import ManagerMenu from '@/components/LeftSideMenu/ManagerMenu.vue'
import StaffMenu from '@/components/LeftSideMenu/StaffMenu.vue'
import Utility from '@/components/UtilityColumn.vue'
import CurrentUser from '@/components/CurrentUserPane.vue'
import PageTitle from '@/components/PageTitle.vue'
import ModalWindow from '@/components/ModalWindow.vue'
export default {
  components: {
    AdminMenu,
    OwnerMenu,
    ManagerMenu,
    StaffMenu,
    Utility,
    CurrentUser,
    PageTitle,
    ModalWindow
  },
  data () {
    return {
      dom: this.$store.getters['Auth/domain'],
      capability: this.$store.getters['Auth/capability'],
      restorable: this.$store.getters['Auth/restorable'],
      error: false,
      errorMsg: '',
      modal: false,
      menu: false
    }
  },
  mounted () {
    // 現在のユーザーの権限
    this.$store.watch(
      (state, getters) => getters['Auth/capability'],
      (newValue) => {
        this.capability = newValue
      }
    )
    // Processing表示
    this.$store.watch(
      (state, getters) => getters.processing,
      (newValue) => {
        this.processing = newValue
        const body = document.querySelector('body')
        if (newValue) {
          body.classList.add('processing')
        } else {
          body.classList.remove('processing')
        }
      }
    )
    // Error表示
    this.$store.watch(
      (state, getters) => getters.showError,
      (newValue) => {
        this.error = newValue
        this.errorMsg = this.$store.getters.errorMsg
      }
    )
    // Modal
    this.$store.watch(
      (state, getters) => getters['Modal/show'],
      (newValue) => {
        this.modal = newValue
      }
    )
    // Menu開閉
    this.$store.watch(
      (state, getters) => getters.showMenu,
      (newValue) => {
        this.menu = newValue
      }
    )
    // 元のログイン状態復元可能か
    this.$store.watch(
      (state, getters) => getters['Auth/restorable'],
      (newValue) => {
        this.restorable = newValue
      }
    )
    // domain
    this.$store.watch(
      (state, getters) => getters['Auth/domain'],
      (newValue) => {
        this.dom = newValue
      }
    )
  },
  methods: {
    logout () {
      this.$store.commit('showMenu', false)
      this.$store.dispatch('Auth/logout')
    },
    setMeta (route) {
      if (route.meta.title) {
        const setTitle = route.meta.title + ' | COCOMIRA人事評価'
        document.title = setTitle
      }
    },
    resetError () {
      this.$store.commit('showError', false)
      this.$store.commit('errorMsg', '')
      this.$router.push('/')
    },
    toggleMenu () {
      const showMenu = this.$store.getters.showMenu
      this.$store.commit('showMenu', !showMenu)
    },
    //
    // 元のログイン状態を復元
    restoreOriginalLogin () {
      this.$store.dispatch('Auth/restoreLogin')
    }
  },
  watch: {
    '$route' (route, from) {
      this.setMeta(route)
    }
  }
}
</script>
