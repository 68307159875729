import axios from 'axios'
const state = {
  status: false,
  list: []
}
const getters = {
  status: (state) => state.status,
  list: (state) => state.list
}
const mutations = {
  status (state, status) {
    state.status = status
  },
  list (state, list) {
    state.list = list
  }
}
const actions = {
  // スタッフ：ステータスチェック
  chkStatus ({ context }) {
    axios
      .get('/wp-json/jjs/v2/oneonone/chkstatus')
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  // ログリスト読み込み
  loadlist ({ commit }, id) {
    axios
      .get('/wp-json/jjs/v2/oneonone/' + id)
      .then((response) => {
        console.log(response.data)
        commit('list', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  // スタッフ：１on１リクエスト
  async request ({ rootGetters, commit, dispatch }, data) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/jjs/v2/oneonone/request', data)
        .then((response) => {
          console.log(response.data)
          dispatch('loadlist', rootGetters['Auth/userid'])
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  // 上司：リプライ
  async reply ({ commit }, data) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .patch('/wp-json/jjs/v2/oneonone/reply', data)
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  }
  // スタッフ、上司：スタッフID固定で過去１on１リスト
}

const OneOnOne = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
export default OneOnOne
