<template>
  <div>
    <ul v-if="Object.keys(companyList).length > 1">
      <li><router-link :to="{ name: 'OwnerLobby' }">管理対象企業リスト</router-link></li>
    </ul>
    <ul v-if="domain">
      <li><router-link :to="{ name: 'Client', params: { dom: domain } }">ダッシュボード</router-link></li>
      <li><router-link :to="{ name: 'StaffList', params: { dom: domain } }">社員</router-link></li>
      <li><router-link :to="{ name: 'EvalGroup', params: { dom: domain } }">評価グループ</router-link></li>
      <li><router-link :to="{ name: 'EmployeeMaster', params: { dom: domain } }">社員マスター</router-link></li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      domain: localStorage.getItem('domain'),
      companyList: this.$store.getters['Owner/companyList']
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters['Auth/domain'],
      (newValue) => {
        this.domain = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters['Owner/companyList'],
      (newValue) => {
        this.companyList = newValue
      }
    )
  },
  methods: {
  },
  watch: {
  }
}
</script>
